<template>
  <div>
    <el-upload action="#" :show-file-list="false" :on-change="handleFileChange" :accept="'image/*'" drag v-if="!imageUrl">
      <div class="upload">
        <UploadFilled style="width: 3em; height: 3em; margin-right: 8px" />
        <span>{{ uploadDesc }}</span>
        <span class="tips">注意：二维码原图不会保存在服务器</span>
      </div>
    </el-upload>
    <div v-else class="preview">
      <img :src="imageUrl" alt="二维码图片" class="qr-code" />
      <div class="result">
        <div class="payload">
          {{ parsedText }}
        </div>
        <div class="toolbar">
          <span v-if="parsedText.indexOf('xxl') > -1">休闲乐手牌号：{{xxlTicketSn}}</span>
          <el-tag type="info" size="small" v-if="readonly && isH5">H5</el-tag>
          <el-tag type="success" size="small" v-if="readonly && !isH5">小程序</el-tag>
          <el-button v-if="!readonly"
                     type="danger" plain @click="onClickClear" :icon="Delete" size="small">清空</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import jsQR from 'jsqr';
import {Delete, UploadFilled} from '@element-plus/icons-vue';
import QRCode from 'qrcode'

const uploadDesc =ref('')
const props = defineProps(['uploadDesc', 'textInputed', 'readonly', 'isH5', 'xxlTicketSn']);
uploadDesc.value = props.uploadDesc ? props.uploadDesc : '拖动/点击上传二维码图片'
const readonly = props.readonly ? props.readonly : false;
const isH5 = props.isH5 ? props.isH5 : false;
const emits = defineEmits(['getResult', 'clearAll']);

const parsedText = ref('');
const imageUrl = ref('')
if (props.textInputed ) {
  QRCode.toDataURL(props.textInputed)
      .then(url => {
        imageUrl.value = url
        parsedText.value = props.textInputed
      })
      .catch(err => {
        console.error(err)
      })
}

const onClickClear = () => {
  resetAll()
  emits("clearAll");
};
const resetAll = () => {
  imageUrl.value = '';
  parsedText.value = '';
}

const handleFileChange = (file) => {
  console.log('on file change', file)
  const reader = new FileReader();
  reader.onload = () => {
    imageUrl.value = reader.result;

    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, image.width, image.height);
      const imageData = context.getImageData(0, 0, image.width, image.height);
      const code = jsQR(imageData.data, image.width, image.height);
      if (code) {
        parsedText.value = code.data;
        emits("getResult", parsedText.value);
      } else {
        parsedText.value = '未识别到二维码';
        emits("getResult", parsedText.value);
      }
    };
    image.src = reader.result;
  };
  reader.readAsDataURL(file.raw);
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-size: 14px;

  .tips {
    font-size: 11px;
    color: #cccccc;
  }
}

.qr-code {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 1px dashed var(--el-border-color-darker);
}

.preview {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.result {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 14px;
  width: 400px;
  height: 200px;
  border: 1px solid var(--el-border-color-darker);
  border-radius: 4px;

  .payload {
    flex: 1;
    margin: 10px;
    width: 70%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .toolbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-left: 1px solid var(--el-border-color-darker);
    width: 20%;
  }

  .payload {
    margin: 10px;
    width: 70%;
  }
}
</style>
